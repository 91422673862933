import { CommentControllerService } from 'prosumer-app/+scenario';
import {
  EXECUTION_STATUS,
  EXECUTION_STATUS_EDITABLE,
  EXECUTION_STATUS_SUCCESS,
  PROSUMER_USER_ROLES,
  RUN_STATUS_ICON_MAP,
  SCENARIO_TYPE,
} from 'prosumer-app/app.references';
import { Case } from 'prosumer-case/models';
import { CaseFacadeService } from 'prosumer-case/state';
import { ProsumerRoutePathService } from 'prosumer-core/services';
import { Utils } from 'prosumer-core/utils';
import {
  DialogService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-libs/eyes-core';
import {
  BaseComponent,
  BaseDialogComponent,
  contains,
  doNothing,
  fadeInAnimation,
  PageMode,
  rowAnimation,
  StatusMap,
} from 'prosumer-libs/eyes-shared';
import { ProjectFacadeService } from 'prosumer-project/state';
import {
  CompareDialogModel,
  CompareDialogResult,
  MessagesDialogComponent,
  ScenarioCompareDialogComponent,
  ScenarioCreateDialogComponent,
  ScenarioDuplicateDialogComponent,
} from 'prosumer-scenario/components';
import {
  Scenario,
  ScenarioCase,
  ScenarioVariationMap,
} from 'prosumer-scenario/models';
import { ScenarioFacadeService } from 'prosumer-scenario/state';
import { ViewLogsComponent } from 'prosumer-shared/modules/view-logs';
import { PermissionCheckerService } from 'prosumer-shared/services/permission-checker';
import {
  buildEntityId,
  getScenarioIdFrom,
  isResultsDownloadable,
} from 'prosumer-shared/utils';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  filter,
  map,
  mergeMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import {
  CaseDetailsPageService,
  StatusQuery,
} from './case-details-page.service';

@UntilDestroy()
@Component({
  selector: 'prosumer-case-details-page',
  templateUrl: './case-details-page.component.html',
  styleUrls: ['./case-details-page.component.scss'],
  animations: [rowAnimation, fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CaseDetailsPageService],
})
export class CaseDetailsPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  readonly commentController = inject(CommentControllerService);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPERT_TABLE_COLUMNS = [
    'name',
    'runHistory',
    'status',
    'createdDate',
    'launchDate',
    'duration',
    // 'input',
    // 'log',
    // 'outputs',
    'actions',
  ];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPERT_EXCLUSIVE_COLUMNS = ['log'];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BUSINESS_TABLE_COLUMNS = this.EXPERT_TABLE_COLUMNS.filter(
    (col) => !!!this.EXPERT_EXCLUSIVE_COLUMNS.includes(col),
  );
  tableColHeaders: Array<string>;
  matMenuClass: string;

  formData$ = new BehaviorSubject<Case>(undefined);
  mode$ = new BehaviorSubject<PageMode>('read');
  compareOptions$ = new BehaviorSubject<ScenarioCase[]>([]);
  selectedCaseNodeType = '';
  viewDialogRef: MatDialogRef<ViewLogsComponent>;
  isClientExpert: boolean;

  readonly theScenarios$ = this.service.selectScenarios();

  constructor(
    private readonly service: CaseDetailsPageService,
    private route: ActivatedRoute,
    private permissionChecker: PermissionCheckerService,
    private caseFacade: CaseFacadeService,
    private projectFacade: ProjectFacadeService,
    private routerStore: RouterStore,
    private userFacade: UserFacadeService,
    private matDialogService: MatDialog,
    private dialogService: DialogService,
    public routePath: ProsumerRoutePathService,
    public scenarioFacade: ScenarioFacadeService,
    public translateService: TranslateService,
    public router: Router,
  ) {
    super();
  }

  /* Route */
  routeMode$ = this.route.data.pipe(
    take(1),
    filter((data) => !!data),
    map((data) => data.mode || 'read'),
    this.takeUntilShare(),
  );

  routeParams$ = this.route.params.pipe(
    take(1),
    filter((params) => !!params),
    this.takeUntilShare(),
  );

  // Indicator when to fetch data
  fetchData$ = this.routeMode$.pipe(
    filter((mode) => mode === 'update' || mode === 'read'),
    this.takeUntilShare(),
  );

  /* Project */
  projectIdParam$ = this.routeParams$.pipe(
    map((params) => params.projectId),
    tap((projectId) => this.projectFacade.selectId(projectId)),
    this.takeUntilShare(),
  );

  /* Case */
  caseIdParam$ = this.routeParams$.pipe(
    map((params) => params.caseId),
    tap((caseId) => this.caseFacade.selectId(caseId)),
    this.takeUntilShare(),
  );

  caseMap$ = this.caseFacade.dataMap$.pipe(this.takeUntilShare());
  selectedCaseId$ = this.caseFacade.selectedId$.pipe(this.takeUntilShare());
  selectedCase$ = this.caseFacade.selectedData$.pipe(this.takeUntilShare());

  /* Project */
  selectedProject$ = this.projectFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  // Trigger when to fetch case data
  getCaseData$ = this.fetchData$.pipe(
    mergeMap(() => this.selectedCase$),
    take(1),
    filter((_case) => !!!_case),
    withLatestFrom(this.caseIdParam$, this.projectIdParam$),
    tap(([_case, caseId, projectId]) =>
      this.caseFacade.get(caseId, { projectId }),
    ),
    this.takeUntilShare(),
  );

  getProjectData$ = this.selectedProject$.pipe(
    take(1),
    filter((project) => !!!project),
    withLatestFrom(this.projectIdParam$),
    tap(([project, projectId]) => this.projectFacade.get(projectId)),
    this.takeUntilShare(),
  );

  /* Progress Indicators */
  loading$ = this.selectedCase$.pipe(
    filter((_case) => !!_case),
    map((_case) => _case.loading),
    this.takeUntilShare(),
  );

  loaded$ = this.selectedCase$.pipe(
    filter((_case) => !!_case),
    map((_case) => _case.loaded),
    this.takeUntilShare(),
  );

  updating$ = this.selectedCase$.pipe(
    filter((_case) => !!_case),
    map((_case) => _case.updating),
    this.takeUntilShare(),
  );

  /* Users */
  users$ = this.userFacade.dataMap$.pipe(this.takeUntilShare());

  /* Current User */
  currentUser$ = this.userFacade.clientUser$.pipe(this.takeUntilShare());

  owner$ = this.users$.pipe(
    filter((users) => !!users),
    mergeMap((users) =>
      this.selectedCase$.pipe(
        filter((_case) => !!_case),
        map((_case) => (users[_case.owner] || {}).fullName),
      ),
    ),
    this.takeUntilShare(),
  );

  /* Scenarios */
  scenarioLoadingList$ = this.scenarioFacade.loadingList$.pipe(
    this.takeUntilShare(),
  );

  scenarioLoadedList$ = this.scenarioFacade.loadedList$.pipe(
    this.takeUntilShare(),
  );

  scenarios$ = this.routeParams$.pipe(
    mergeMap((params) =>
      this.scenarioFacade.filterScenarios$(params.projectId, params.caseId),
    ),
  );

  filterPredicate = (data: any, filterData: string) =>
    contains(
      data?.name.trim().toLowerCase(),
      filterData.trim().toLowerCase(),
    ) ||
    contains(
      data?.run?.status.trim().toLowerCase(),
      filterData.trim().toLowerCase(),
    );

  // Trigger when to fetch scenario list data
  getScenarioList$ = this.fetchData$.pipe(
    mergeMap(() => this.selectedCaseId$),
    take(1),
    withLatestFrom(this.projectIdParam$),
    mergeMap(([caseId, projectId]) =>
      this.scenarioFacade.getCaseDetailScenarioList(caseId, {
        projectId,
        caseId,
      }),
    ),
    this.takeUntilShare(),
  );

  getScenariosAndVariations$ = this.selectedCaseId$.pipe(
    take(1),
    withLatestFrom(this.projectIdParam$),
    mergeMap(([caseId, projectId]) =>
      this.scenarioFacade.getScenariosAndVariations({
        projectId,
        caseId,
        status: EXECUTION_STATUS_SUCCESS,
      }),
    ),
    take(1),
  );

  /* Filters */
  filters = [
    { field: 'name' },
    {
      type: 'select',
      field: 'status',
      label: 'Status',
      flex: '10%',
      value: '',
      options: [
        { name: 'All', value: '' },
        {
          name: 'Draft',
          value: 'draft',
        },
        { name: 'Succeeded', value: 'succeeded' },
      ],
    },
  ];

  /* Access */
  canEdit$ = this.selectedProject$.pipe(
    mergeMap((project) =>
      this.projectIdParam$.pipe(
        filter(
          (projectID) =>
            !!project && project.loaded && project.id === projectID,
        ),
        mergeMap(() =>
          this.currentUser$.pipe(
            map(
              (user) =>
                this.permissionChecker.isUserPermitted(project, user)[
                  'CAN_UPDATE'
                ],
            ),
          ),
        ),
      ),
    ),
    this.takeUntilShare(),
  );

  canCompare$ = this.compareOptions$.pipe(
    untilDestroyed(this),
    map((arr) => arr.length > 1),
  );

  isClientExpert$ = this.userFacade.clientUserPrefs$.pipe(
    map((prefs) => prefs.prosumerRole === PROSUMER_USER_ROLES.expert),
    this.takeUntilShare(),
  );

  /* Table */
  statusMap: StatusMap = RUN_STATUS_ICON_MAP;

  copyDisabled = (scenario: Scenario) =>
    scenario.scenarioType === SCENARIO_TYPE.UPLOADED ||
    scenario.copyStatus !== undefined;

  editDisabled = (data: Scenario) =>
    !data || (data?.run && this._hasStartedSimulation(data));

  linkDisabled = (data: Scenario) =>
    !data ||
    !!data.error ||
    !!data.loading ||
    !!data.copyStatus ||
    (this.isScenarioUploaded(data) &&
      data.run?.status !== EXECUTION_STATUS.SUCCESS &&
      data.run?.status !==
        EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS &&
      data.run?.status !== EXECUTION_STATUS.SUCCESS_WITH_WARNINGS);

  importMessageDisabled = (data: Scenario) =>
    (data && !data.run) ||
    (data && !(data.run as any).errorFile) ||
    (data &&
      data.run &&
      !contains(
        [EXECUTION_STATUS.DRAFT, EXECUTION_STATUS.NOT_READY],
        data.run.status,
      )) ||
    data.scenarioType !== SCENARIO_TYPE.CLONED ||
    data.copyStatus !== undefined ||
    data.downloading !== undefined;

  outputDisabled = (data: Scenario) =>
    (data &&
      (!data.run ||
        (data.run &&
          !(
            data.run.status === EXECUTION_STATUS.SUCCESS ||
            data.run.status ===
              EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS ||
            data.run.status === EXECUTION_STATUS.SUCCESS_WITH_WARNINGS
          )))) ||
    data.copyStatus !== undefined ||
    data.downloading !== undefined;

  inputDisabled = (data: Scenario) =>
    !data ||
    data.copyStatus !== undefined ||
    data.downloading !== undefined ||
    contains(
      [
        EXECUTION_STATUS.RUNNING,
        EXECUTION_STATUS.QUEUED,
        EXECUTION_STATUS.READY,
      ],
      data.run.status,
    );

  logDisabled = (data: Scenario) =>
    (data && !data.run) ||
    (data && !(data.run as any).logFile) ||
    (data &&
      data.run &&
      !contains(
        [
          EXECUTION_STATUS.SUCCESS,
          EXECUTION_STATUS.FAILED,
          EXECUTION_STATUS.CANCELLED,
          EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS,
          EXECUTION_STATUS.SUCCESS_WITH_WARNINGS,
          EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
        ],
        data.run.status,
      )) ||
    data.copyStatus !== undefined ||
    data.downloading !== undefined;

  messageDisabled = (data: Scenario) =>
    (data && !data.run) ||
    (data && !(data.run as any).messageFile) ||
    (data &&
      data.run &&
      !contains(
        [
          EXECUTION_STATUS.SUCCESS,
          EXECUTION_STATUS.FAILED,
          EXECUTION_STATUS.CANCELLED,
          EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS,
          EXECUTION_STATUS.SUCCESS_WITH_WARNINGS,
          EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
        ],
        data.run.status,
      )) ||
    data.copyStatus !== undefined ||
    data.downloading !== undefined;

  resultsDisabled = (data: Scenario) =>
    (data &&
      (!data.run ||
        (data.run &&
          !(
            data.run.status === EXECUTION_STATUS.SUCCESS ||
            data.run.status ===
              EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS ||
            data.run.status === EXECUTION_STATUS.SUCCESS_WITH_WARNINGS
          )))) ||
    data.copyStatus !== undefined;

  showRealtimeLogsItem = (data: Scenario) =>
    !data || data?.run?.status === EXECUTION_STATUS.RUNNING;

  viewLogDisabled = (data) => {
    const viewDialogRef = Utils.resolveToEmptyObject(this.viewDialogRef);
    const viewDialogComponent = Utils.resolveToEmptyObject(
      viewDialogRef.componentInstance,
    );
    if (
      data &&
      data.run &&
      (data.run.status === EXECUTION_STATUS.SUCCESS ||
        data.run.status ===
          EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS ||
        data.run.status === EXECUTION_STATUS.IMPOSSIBLE_SIMULATION ||
        data.run.status === EXECUTION_STATUS.SUCCESS_WITH_WARNINGS) &&
      data.id ===
        Utils.resolveToEmptyObject(viewDialogComponent.data).scenarioId
    ) {
      viewDialogComponent.completeLogger();
    }
    return (
      data &&
      data.run &&
      contains(
        [
          EXECUTION_STATUS.SUCCESS,
          EXECUTION_STATUS.FAILED,
          EXECUTION_STATUS.CANCELLED,
          EXECUTION_STATUS.DRAFT,
          EXECUTION_STATUS.QUEUED,
          EXECUTION_STATUS.READY,
          EXECUTION_STATUS.SUCCESS_WITH_UNSATISFIED_CONSTRAINTS,
          EXECUTION_STATUS.SUCCESS_WITH_WARNINGS,
          EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
        ],
        data.run.status,
      )
    );
  };

  isScenarioUploaded = (data: Scenario) =>
    data && (data.scenarioType === SCENARIO_TYPE.UPLOADED || !!data.copyStatus);

  /* Access (method) */
  hasAccess$ = (scenario: Scenario, method: 'CAN_DELETE' | 'CAN_UPDATE') =>
    combineLatest([this.currentUser$, this.selectedProject$]).pipe(
      map(
        ([user, project]) =>
          this.permissionChecker.isUserPermitted(project, user, scenario)[
            method
          ],
      ),
      this.takeUntilShare(),
    );

  ngOnInit() {
    this.scenarioFacade.selectId(undefined);
    this.routeMode$.subscribe((mode) => this.mode$.next(mode));
    this.isClientExpert$.subscribe((isExpert: boolean) => {
      this.isClientExpert = isExpert;
      this.matMenuClass = 'scenario-actions-biz-menu';
      if (isExpert) {
        this.matMenuClass = 'scenario-actions-expert-menu';
      }
    });
    this.dispatchSelectScenariosAndVariations();
    this.subscribeToSetEditPermission();
  }

  ngAfterViewInit() {
    this.getProjectData$.subscribe();
    this.getCaseData$.subscribe();
    this.selectedCase$
      .pipe(
        filter((caseData) =>
          [
            !!caseData,
            caseData.loaded,
            !caseData.loading,
            !caseData.updating,
            !caseData.error,
          ].every(Boolean),
        ),
        tap((caseData) => (this.selectedCaseNodeType = caseData.nodeType)),
      )
      .subscribe((_case) => this.formData$.next(_case));
    this.getScenarioList$.subscribe();
  }

  onUpdate(_case: Case) {
    this.caseFacade
      .update(_case)
      .pipe(this.takeUntilShare())
      .subscribe(() => {
        this.mode$.next('read');
        this.formData$.next(_case);
      });
  }

  onCancel(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.mode$.getValue() === 'update'
      ? this.mode$.next('read')
      : this.routerStore.back();
  }

  onEdit(): void {
    this.mode$.next('update');
  }

  onDownloadInput(data: Scenario) {
    if (
      data.scenarioType === SCENARIO_TYPE.UPLOADED ||
      data.inputFileGenerated
    ) {
      // download input file normally if scenario is uploaded or inputFile is updated
      this.scenarioFacade.download(data, 'getSigned', 'input');
      return;
    }
    this.scenarioFacade.getDraftInput(data, 'input');
  }

  onDownloadLog(data: Scenario) {
    this.scenarioFacade.download(data, 'getLogSigned', 'log');
  }

  onViewLog(data: Scenario) {
    const { projectId, caseId, id: scenarioId } = data;
    this.viewDialogRef = this.matDialogService.open(ViewLogsComponent, {
      width: '800px',
      minHeight: '300px',
      data: {
        projectId,
        caseId,
        scenarioId,
      },
    });
  }

  onDownloadOutput(data: Scenario) {
    this.scenarioFacade.download(data, 'getOutputSigned', 'output');
  }

  onCopy(data: Scenario) {
    this.dialogService
      .openDialog(ScenarioDuplicateDialogComponent, {
        duplicateName: data.name,
        entityArchetype: 'scenario',
        width: 500,
        disableClose: true,
        existingData$: this.scenarios$,
        scenario: data,
        selectedCaseNodeType: this.selectedCaseNodeType,
      })
      .subscribe((dialogData) =>
        dialogData
          ? this.scenarioFacade.copy(
              data,
              dialogData.duplicateName,
              dialogData.projectId,
              dialogData.caseId,
            )
          : doNothing(),
      );
  }

  onDelete(data: any) {
    this.dialogService
      .openDialog(BaseDialogComponent, {
        title: 'Delete Scenario',
        message: `Do you wish to delete "${data.name}" scenario?`,
        confirm: 'Delete',
        close: 'Cancel',
      })
      .subscribe((scenario: any) =>
        scenario ? this.scenarioFacade.delete(data) : doNothing(),
      );
  }

  onShowImportMessage(data: Scenario) {
    if (this.importMessageDisabled(data)) {
      return;
    } // Show nothing if messages aren't present (disabled)
    this.dialogService
      .openDialog(MessagesDialogComponent, {
        dialogTitle: this.translateService.instant(
          'Message.labels.dialogTitleClone',
        ),
        data: { ...data, source: 'Excel Import' },
        width: 800,
      })
      .subscribe((x: any) => doNothing());
  }

  onCompareScenarios() {
    this.compareOptions$
      .pipe(
        mergeMap((scenarioOptions) =>
          this.dialogService.openDialog(ScenarioCompareDialogComponent, {
            title: 'Compare Scenarios',
            width: 600,
            scenarioOptions,
          } as CompareDialogModel),
        ),
      )
      .pipe(
        take(1),
        mergeMap((compareDialogResult: CompareDialogResult) =>
          combineLatest([
            this.projectIdParam$,
            this.caseIdParam$,
            of(compareDialogResult),
          ]),
        ),
      )
      .subscribe(([projectId, caseId, compareDialogResult]) => {
        if (this.hasReferenceAndSelectedScenarios(compareDialogResult)) {
          this.routerStore.go(
            this.buildCompareURLFragments(
              compareDialogResult,
              projectId,
              caseId,
            ),
            this.buildQueryParams(compareDialogResult),
          );
        }
      });
  }

  navigateToViewScenario(row) {
    this.router.navigate(
      this.routePath.viewScenario(row.projectId, row.caseId, row.id),
    );
  }

  navigateToEditScenario(row) {
    this.router.navigate(
      this.routePath.updateScenario(row.projectId, row.caseId, row.id),
    );
  }

  navigateToShowResults(row) {
    if (isResultsDownloadable(row) && !row.loading && !row.error) {
      this.router.navigate(
        this.routePath.scenarioResults(row.projectId, row.caseId, row.id),
      );
    }
  }

  onNavigateScenario(row: Scenario): void {
    if (!this.isScenarioUploaded(row)) {
      this.canEdit$.pipe(take(1)).subscribe((canEdit) => {
        if (canEdit && this._isScenarioEditable(row)) {
          this.navigateToEditScenario(row);
        } else {
          this.navigateToViewScenario(row);
        }
      });
    } else {
      this.navigateToShowResults(row);
    }
  }

  determineRoutePathBehavior = (data: Scenario) =>
    this._hasStartedSimulation(data)
      ? this.routePath.viewScenarioRelative(data.id)
      : this.routePath.updateScenarioRelative(data.id);

  _hasStartedSimulation = (data: Scenario) =>
    EXECUTION_STATUS.DRAFT !== data.run.status;

  _isScenarioEditable = (data: Scenario) =>
    EXECUTION_STATUS_EDITABLE.includes(data.run.status);

  onStatusChange(status: StatusQuery): void {
    this.service.setStatusGroupQuery(status);
  }

  onSearch(query: string): void {
    this.service.setSearchQuery(query);
  }

  onBatchDeleteSuccess(): void {
    this.service.dispatchGetScenariosOfActive();
    this.dispatchSelectScenariosAndVariations();
  }

  getCreateRouterLink(): Observable<string[]> {
    return this.canEdit$.pipe(
      map((canEdit: boolean) => {
        if (canEdit) {
          return this.routePath.createScenarioRelative();
        }
        return null;
      }),
    );
  }

  getUploadRouterLink(): Observable<string[]> {
    return this.canEdit$.pipe(
      map((canEdit: boolean) => {
        if (canEdit) {
          return this.routePath.uploadScenarioRelative();
        }
        return null;
      }),
    );
  }

  subscribeToSetEditPermission(): void {
    this.canEdit$
      .pipe(tap((permission) => this.service.setEditPermission(permission)))
      .subscribe();
  }

  private dispatchSelectScenariosAndVariations(): void {
    this.service.selectScenariosAndVariations().subscribe((list: unknown[]) => {
      this.compareOptions$.next(this.updateScenarioIdToAppendVariation(list));
    });
  }

  private updateScenarioIdToAppendVariation(
    scenarios: Array<Scenario & ScenarioVariationMap>,
  ): Array<Scenario & ScenarioVariationMap> {
    return scenarios.map((scenario) => ({
      ...scenario,
      id: buildEntityId(scenario.id, scenario.variationId),
    }));
  }

  private buildCompareURLFragments(
    from: CompareDialogResult,
    projectId: string,
    caseId: string,
  ): string[] {
    return this.routePath.compare(
      projectId,
      caseId,
      getScenarioIdFrom(from.referenceId),
    );
  }

  private buildQueryParams(from: CompareDialogResult): Record<string, string> {
    return {
      reference: from.referenceId,
      selected: this.buildSelectedParam(from.selectedScenarios),
    };
  }

  private buildSelectedParam(
    scenarios: Array<Scenario & ScenarioVariationMap>,
  ): string {
    return scenarios.map((scenario) => scenario.id).join(',');
  }

  private hasReferenceAndSelectedScenarios(data: CompareDialogResult): boolean {
    return !!data && [data.referenceId, data.selectedScenarios].every(Boolean);
  }

  onNewScenario() {
    this.dialogService
      .openDialog(ScenarioCreateDialogComponent, {
        existingData$: this.scenarios$,
        caseData$: this.formData$,
        width: 500,
      })
      .subscribe((dialogData) => {
        if (!!dialogData.scenario) {
          this.navigateToEditScenario({
            projectId: dialogData.scenario.projectUuid,
            caseId: dialogData.scenario.caseUuid,
            id: dialogData.scenario.scenarioUuid,
          });
        }
      });
  }
}
