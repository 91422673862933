import { MarkdownModule } from 'ngx-markdown';
import {
  ScenarioListColumn,
  ScenarioListModule,
} from 'prosumer-app/+scenario/components/scenario-list';
import { ScenarioStatusDialogModule } from 'prosumer-app/+scenario/components/scenario-status-dialog/scenario-status-dialog.module';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { SharedModule } from 'prosumer-shared/shared.module';

import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';

import { CommentsComponent } from '../+scenario/containers/comments';
import { CommentParentRefreshDirective } from '../+scenario/directives/comment-parent-refresh';
import { CaseRoutingModule } from './case-routing.module';
import { CaseFormComponent } from './components';

import {
  CaseCreatePageComponent,
  CaseDetailsPageComponent,
  CaseListPageComponent,
} from './containers';

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    SharedModule,
    CaseRoutingModule,
    MarkdownModule.forChild(),
    ScenarioStatusDialogModule,
    ScenarioListModule.forChild({
      columns: [
        ScenarioListColumn.name,
        ScenarioListColumn.runHistory,
        ScenarioListColumn.launchDate,
        ScenarioListColumn.duration,
        ScenarioListColumn.tco,
        ScenarioListColumn.co2FinalYear,
        ScenarioListColumn.comments,
        ScenarioListColumn.actions,
      ],
    }),
    LetDirective,
    PushPipe,
    CommentsComponent,
    CommentParentRefreshDirective,
  ],
  declarations: [
    CaseFormComponent,
    CaseDetailsPageComponent,
    CaseCreatePageComponent,
    CaseListPageComponent,
  ],
  exports: [
    CaseFormComponent,
    CaseDetailsPageComponent,
    CaseCreatePageComponent,
    CaseListPageComponent,
  ],
})
export class CaseModule {}
