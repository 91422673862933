<prosumer-page prosumerCommentParentRefresh parentType="scenario">
  <div class="flex gap-3">
    <div fxLayout="column" fxLayoutGap="10px" class="w-full">
      <prosumer-progress-card
        [showProgress]="(updating$ | async) || (scenarioLoadingList$ | async)"
      >
        <prosumer-case-form
          [loading]="loading$ | async"
          [saving]="updating$ | async"
          [mode]="mode$ | async"
          [data]="formData$ | async"
          [owner]="owner$ | async"
          [canEdit]="canEdit$ | async"
          (edit)="onEdit()"
          (save)="onUpdate($event)"
          (cancel)="onCancel()"
        >
          <div fxLayout="column" fxLayoutGap="10px">
            <!-- Table Filters -->
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
            >
              <div
                *ngFor="let filter of filters; let i = index"
                [fxFlex]="filter?.flex || '50%'"
              >
                <prosumer-select
                  *ngIf="filter?.type === 'select'; else input"
                  class="eyes-maximize-width"
                  [label]="filter?.label || 'Select'"
                  [placeholder]="filter?.placeholder"
                  [disabled]="loading$ | async"
                  [options]="filter?.options"
                  [value]="filter?.value"
                  (eyesSelectionChange)="onStatusChange($event)"
                >
                </prosumer-select>

                <ng-template #input>
                  <prosumer-filter-input
                    flex=""
                    (filter)="onSearch($event)"
                    placeholdermsg="Search for scenarios"
                    label="Search for scenarios"
                    class="eyes-maximize-width"
                    data-testid="scenario-filter-input"
                  >
                  </prosumer-filter-input>
                </ng-template>
              </div>

              <div fxFlex></div>
              <!-- Table buttons -->
              <div
                class="eyes-button-container"
                fxLayout="row wrap"
                fxLayoutAlign="end center"
                fxLayoutGap="10px"
              >
                <a
                  data-testid="case-create-scenario"
                  mat-button
                  type="button"
                  color="primary"
                  [disabled]="(canEdit$ | async) !== true"
                  (click)="onNewScenario()"
                >
                  <mat-icon aria-hidden="false" matPrefix>add</mat-icon>
                  <b>{{ 'Case.labels.newScenario' | translate }}</b>
                </a>
                <a
                  *ngIf="isClientExpert$ | async"
                  data-testid="case-upload-scenario"
                  mat-button
                  type="button"
                  color="primary"
                  [disabled]="(canEdit$ | async) !== true"
                  [routerLink]="getUploadRouterLink() | async"
                >
                  <mat-icon aria-hidden="false" matPrefix>attachment</mat-icon>
                  <b>{{ 'Case.labels.uploadScenario' | translate }}</b>
                </a>
                <button
                  mat-button
                  data-testid="case-compare-scenario"
                  type="button"
                  color="primary"
                  [disabled]="(canCompare$ | async) !== true"
                  (click)="onCompareScenarios()"
                >
                  <mat-icon aria-hidden="false" matPrefix
                    >compare_arrows</mat-icon
                  >
                  <b>{{ 'Case.labels.compareScenarios' | translate }}</b>
                </button>
              </div>
            </div>

            <prosumer-scenario-list
              [attr.data-testid]="'case-details-scenarios'"
              [scenarios]="theScenarios$ | ngrxPush"
              [loading]="scenarioLoadingList$ | ngrxPush"
              (nameClick)="onNavigateScenario($event)"
              [allowEditScenarioName]="canEdit$ | async"
              [isRowSelectable]="canEdit$ | async"
              (deleteSuccess)="onBatchDeleteSuccess()"
            ></prosumer-scenario-list>
          </div>
        </prosumer-case-form>
      </prosumer-progress-card>
    </div>
    @if (commentController.showComments()) {
      <prosumer-comments class="w-24rem"></prosumer-comments>
    }
  </div>
</prosumer-page>
